<template>
  <v-container>
    <div v-for="section in sections" v-bind:key="section.id">
      <v-row v-if="section.includeEstimate">
        <v-col cols="12" xl="8" lg="8" md="8" sm="12">
          <text-view
            v-if="section.type == 1"
            :section="section"
            :class="section.backgroundColor"
          ></text-view>
          <image-view
            v-if="section.type == 2"
            :section="section"
          ></image-view>
          <image-text-view
            v-if="section.type == 3"
            :section="section"
          ></image-text-view>
          <carousel-view
            v-if="section.type == 4"
            :section="section"
          ></carousel-view>
          <card-view
            v-if="section.type == 5"
            :section="section"
          ></card-view>
          <cards-view
            v-if="section.type == 6"
            :section="section"
          ></cards-view>
          <video-view
            v-if="section.type == 7"
            :section="section"
          ></video-view>
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="12">
          <estimate></estimate>
        </v-col>
      </v-row>
      <v-row v-if="!section.includeEstimate">
        <text-view
          v-if="section.type == 1"
          :section="section"
          :class="section.backgroundColor"
        ></text-view>
        <image-view
          v-if="section.type == 2"
          :section="section"
        ></image-view>
        <image-text-view
          v-if="section.type == 3"
          :section="section"
        ></image-text-view>
        <carousel-view
          v-if="section.type == 4"
          :section="section"
        ></carousel-view>
        <card-view
          v-if="section.type == 5"
          :section="section"
        ></card-view>
        <cards-view
          v-if="section.type == 6"
          :section="section"
        ></cards-view>
          <video-view
            v-if="section.type == 7"
            :section="section"
          ></video-view>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import apiService from "@/_services/api-service";
import cardView from "@/views/Pages/Card-View";
import cardsView from "@/views/Pages/Cards-View";
import carouselView from "@/views/Pages/Carousel-View";
import estimate from "../components/controls/Estimate";
import imageView from "@/views/Pages/Image-View";
import imageTextView from "@/views/Pages/ImageText-View";
import store from "@/store";
import textView from "@/views/Pages/Text-View";
import videoView from "@/views/Pages/Video-View";

export default {
  components: {
    estimate,
    "card-view": cardView,
    "cards-view": cardsView,
    "carousel-view": carouselView,
    "image-view": imageView,
    "image-text-view": imageTextView,
    "text-view": textView,
    "video-view": videoView,
  },

  computed: {},

  created() {
    // console.log("Dynamic.created()")
    this.get();
  },

  data: () => ({
    page: {
      id: 0,
      name: "",
      path: "",
      title: "",
    },
    sections: [],
  }),

  methods: {
    compare(a, b) {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }
      return 0;
    },

    get() {
      // console.log('Dynamic.get()')
      let url = "page/" + this.$route.params.id;
      apiService.get(url, this.getPageSuccess, this.getPageError);
      url = "section/getsections/" + this.$route.params.id;
      apiService.post(url, {}, this.getSectionSuccess, this.getSectionError);
    },

    getPageSuccess(result) {
      this.page = result.data;
    },
    getPageError(error) {
      const notification = {
        title: "Error getting the Page",
        error: error,
      };
      store.commit("setResponseError", notification);
    },

    getSectionSuccess(result) {
      this.sections = result.data;
      this.sections.sort((a, b) => (a.order > b.order ? 1 : -1));
    },
    getSectionError(error) {
      const notification = {
        title: "Error getting the Page sections",
        error: error,
      };
      store.commit("setResponseError", notification);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>